<template>
  <div class="wrapper-editors-manage">
    <b-modal
      id="editors-manage"
      v-model="isShowModal"
      no-enforce-focus
      hide-footer
      @hide="$emit('hide')"
    >
      <div
        class="content-editors-manage"
        @focusin.stop
      >
        <b-form class="editors-form">
          <h3 class="editors-form__title">
            Add {{ entity }}s
          </h3>

          <span class="editors-form__sub_title">
            Select one or several editors from the dropdown and add them to the page
          </span>

          <div class="editors-search">
            <span>
              {{ entity | capitalizeFirstLetter }}
              {{
                this.$refs.eDropdown
                  ? this.$refs.eDropdown.activeItems.length
                  : ''
              }}
            </span>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend
                class="no-border"
                is-text
              >
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input
                v-model="search"
                type="search"
                class="no-border"
                :placeholder="$t('Search...')"
              />
            </b-input-group>
            <!-- List -->
            <e-dropdown
              ref="eDropdown"
              :items="users"
              type="multiple"
            />
          </div>

          <div class="editors-form__actions">
            <b-button
              variant="outline-primary"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="mr-1"
              @click="sendReport"
            >
              Send
            </b-button>
          </div>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios'

import {
  BModal,
  BForm,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BButton,
} from 'bootstrap-vue'
import eDropdown from '@/elements/e-dropdown'
import { debounce } from 'vue-debounce'

export default {
  components: {
    BModal,
    BForm,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    eDropdown,
    BButton,
  },
  filters: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
  props: {
    entity: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowModal: true,
      users: [],
    }
  },
  computed: {
    search: {
      get() { return '' },
      // eslint-disable-next-line
      set: debounce(function(search) {
        this.searchCompanyManagers(search)
      }, 500),
    },
  },
  methods: {
    async searchCompanyManagers(name) {
      try {
        const res = await axiosIns.get(`profile/search?name=${name}&type=user`)

        if (res.status === 200) {
          this.users = res.data.data
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async sendReport() {
      if (this.$refs.eDropdown.activeItems.length) {
        let params = {}

        if (this.entity === 'admin') {
          params = {
            admin: this.$refs.eDropdown.activeItems,
          }
        } else if (this.entity === 'editor') {
          params = {
            editor: this.$refs.eDropdown.activeItems,
          }
        } else {
          params = {
            viewer: this.$refs.eDropdown.activeItems,
          }
        }

        try {
          const res = await axiosIns.put(`company-managers/${this.$route.params.id}`, params)

          if (res.status === 200) {
            this.$emit('hide')
            this.$emit('get-сompany-managers')
          }
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
          throw e
        }
      }
    },
    closeModal() {
      this.$emit('hide')
    },
  },
}
</script>
