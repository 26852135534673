<template>
  <validation-observer
    v-if="isShow"
    ref="profileCompanyRules"
    v-slot="{ invalid }"
  >
    <b-row class="profile-company-manage-form">
      <b-col
        cols="12"
        lg="9"
      >
        <b-form class="profile-company-form">
          <div class="profile-company-form__title">
            General information
          </div>
          <b-row class="profile-company-form__block general-information">
            <b-col cols="10">
              <validation-provider
                #default="{ errors }"
                name="Profile name"
                rules="required|min:3|max:30"
              >
                <b-form-input
                  id="profile-name"
                  v-model="form.name"
                  type="text"
                  placeholder="Profile name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="5">
              <label for="administrator">Administrator</label>
              <v-select
                id="administrator"
                v-model="administrator"
                class="select2"
                label="name"
                placeholder="Administrator"
                :options="getAdministrators"
                disabled
              />
            </b-col>
          </b-row>

          <b-row class="profile-company-form__categories-block">
            <b-col cols="5">
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <label for="administrator">Category</label>
                <v-select
                  id="administrator"
                  v-model="category"
                  class="select2"
                  label="name"
                  placeholder="Category"
                  :options="categories"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="5">
              <validation-provider
                #default="{ errors }"
                name="Subcategory"
                rules="required"
              >
                <label for="administrator">Subcategory</label>
                <v-select
                  id="administrator"
                  v-model="subCategory"
                  class="select2"
                  label="name"
                  placeholder="Subcategory"
                  :options="getSubCategories"
                  :disabled="!category"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="profile-company-form__title">
            Contact information
          </div>
          <b-row class="profile-company-form__block contact-information">
            <b-col cols="10">
              <label for="address-line">Address line</label>
              <b-form-input
                id="address-line"
                v-model="form.address"
                type="text"
                placeholder="Address line"
              />
            </b-col>
            <b-col cols="5">
              <label for="telephone">Telephone</label>
              <b-form-input
                id="telephone"
                v-model="form.phone"
                type="tel"
                placeholder="Telephone"
              />
            </b-col>
            <b-col cols="5">
              <validation-provider
                ref="emailProvider"
                #default="{ errors }"
                name="E-post"
                rules="required|email"
              >
                <label for="e-post">E-post</label>
                <b-form-input
                  id="e-post"
                  v-model="form.email"
                  type="email"
                  placeholder="E-post"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="profile-company-form__b-title">
            <div>About your company</div>
            <span>You can add it later while editing the profile</span>
          </div>
          <b-row class="profile-company-form__block about-your-company">
            <b-col cols="10">
              <validation-provider
                #default="{ errors }"
                name="Description"
                rules="required"
              >
                <label for="description">Description</label>
                <TinyMCE
                  id="description"
                  v-model="form.about"
                  :placeholder="$t('Place some text there...')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <div
            v-if="company && isOwner"
            class="editors"
          >
            <b-row class="editors__title">
              <b-col cols="4">
                <span>Admins - {{ admins.length }}</span>
                <img
                  :src="plusCircleImage"
                  alt="Add admins"
                  @click="() => openModal('admin')"
                >
              </b-col>
              <b-col cols="4">
                <span>Editors - {{ editors.length }}</span>
                <img
                  :src="plusCircleImage"
                  alt="Add editors"
                  @click="() => openModal('editor')"
                >
              </b-col>
              <b-col cols="4">
                <span>Viewers - {{ viewers.length }}</span>
                <img
                  :src="plusCircleImage"
                  alt="Add viewers"
                  @click="() => openModal('viewer')"
                >
              </b-col>
            </b-row>
            <b-row class="editors__list">
              <b-col cols="4">
                <ul>
                  <li
                    v-for="(item, index) in admins"
                    :key="index"
                    @click="() => deleteManager(item, 'admin')"
                  >
                    <span>
                      {{ item.user.name }}
                    </span>
                    <img
                      :src="minusCircleImage"
                      alt="Minus admins"
                    >
                  </li>
                </ul>
              </b-col>
              <b-col cols="4">
                <ul>
                  <li
                    v-for="(item, index) in editors"
                    :key="index"
                    @click="() => deleteManager(item, 'editor')"
                  >
                    <span>
                      {{ item.user.name }}
                    </span>
                    <img
                      :src="minusCircleImage"
                      alt="Minus viewers"
                    >
                  </li>
                </ul>
              </b-col>
              <b-col cols="4">
                <ul>
                  <li
                    v-for="(item, index) in viewers"
                    :key="index"
                    @click="() => deleteManager(item, 'viewer')"
                  >
                    <span>
                      {{ item.user.name }}
                    </span>
                    <img
                      :src="minusCircleImage"
                      alt="Minus viewers"
                    >
                  </li>
                </ul>
              </b-col>
            </b-row>
          </div>

          <div class="profile-company-form__b-title">
            <div>Upload logo & wall image</div>
            <span>You can add it later while editing the profile</span>
          </div>

          <b-row class="profile-company-form__block upload-logo">
            <b-col cols="10">
              <label for="add-logo">Add logo</label>
              <div
                id="add-logo"
                class="
                  file-input
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <img
                  :src="fileDropImage"
                  alt="test"
                >
                <h6 class="font-weight-bolder text-center px-1 pt-1">
                  {{ $t("Drop an image here or") }}
                  <span class="text-primary cursor-pointer">
                    {{ $t("click") }}
                  </span>
                  {{ $t("to upload") }}
                </h6>
                <b-form-file
                  id="files"
                  v-model="image"
                  class="drop-input"
                  @input="(file) => uploadImg(file, 'logo')"
                />
              </div>
            </b-col>

            <!-- Placeholder for logo -->
            <b-col cols="10">
              <b-row class="justify-content-between px-1">
                <div
                  v-if="cropperImg"
                  class="col-7 px-0"
                >
                  <cropper
                    :img="cropperImg"
                    :round-crop-area="true"
                    :return-blob="true"
                    @returnImage="saveCompanyImage"
                  />

                </div>
                <div
                  v-if="imagePreview"
                  class="col-4 px-0 py-1"
                >
                  <img
                    :src="imagePreview"
                    alt="Logo company profile image"
                    class="w-100 rounded-circle"
                  >
                </div>
              </b-row>
            </b-col>

            <b-col cols="10">
              <label for="add-wall">Add wall image</label>
              <div
                id="add-wall"
                class="
                  file-input
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <img
                  :src="fileDropImage"
                  alt="test"
                >
                <h6 class="font-weight-bolder text-center px-1 pt-1">
                  {{ $t("Drop an image here or") }}
                  <span class="text-primary cursor-pointer">
                    {{ $t("click") }}
                  </span>
                  {{ $t("to upload") }}
                </h6>
                <b-form-file
                  id="files-cover"
                  v-model="cover"
                  class="drop-input"
                  @input="(file) => uploadImg(file, 'cover')"
                />
              </div>
            </b-col>
            <!-- Placeholder  for wall -->
            <b-col cols="10">
              <div
                v-if="cropperImgCover"
                class="col-10 px-0"
              >
                <cropper
                  :img="cropperImgCover"
                  :return-blob="true"
                  @returnImage="saveCompanyImageCover"
                />

              </div>
              <div
                v-if="coverPreview"
                class="col-8 px-0 py-1"
              >
                <img
                  :src="coverPreview"
                  alt="Wall company profile image"
                  class="w-100"
                >
              </div>
            </b-col>
            <b-col cols="10">
              <label for="add-wall">{{ $t('Add rectangle logo') }}</label>
              <div
                id="add-rectangle-image"
                class="
                  file-input
                  d-flex
                  flex-column
                  align-items-center
                  justify-content-center
                "
              >
                <img
                  :src="fileDropImage"
                  alt="test"
                >
                <h6 class="font-weight-bolder text-center px-1 pt-1">
                  {{ $t("Drop an image here or") }}
                  <span class="text-primary cursor-pointer">
                    {{ $t("click") }}
                  </span>
                  {{ $t("to upload") }}
                </h6>
                <b-form-file
                  id="files-rectangle-image"
                  v-model="rectangleImage"
                  class="drop-input"
                  @input="(file) => uploadImg(file, 'rectangle-logo')"
                />
              </div>
            </b-col>
            <b-col cols="10">
              <div
                v-if="cropperRectangleImage"
                class="col-10 px-0"
              >
                <cropper
                  :img="cropperRectangleImage"
                  :return-blob="true"
                  @returnImage="saveRectangleImageCover"
                />

              </div>
              <div
                v-if="rectangleImagePreview"
                class="col-8 px-0 py-1"
              >
                <img
                  :src="rectangleImagePreview"
                  alt="Rectangle company profile image"
                  class="w-100"
                >
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
      <b-col
        cols="12"
        lg="3"
        class="align-items-end d-flex justify-content-end"
      >
        <div class="float-right">
          <b-button
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="submit"
          >
            {{ company ? 'Update' : 'Create' }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="$router.go(-1)"
          >
            Cancel
          </b-button>
        </div>
      </b-col>
    </b-row>

    <EditorsManage
      v-if="showModal"
      :entity="entity"
      @hide="showModal = false"
      @get-сompany-managers="getCompanyManagers"
    />
  </validation-observer>
</template>

<script>

import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TinyMCE from '@/components/editor/TinyMCE.vue'
import fileDropImage from '@/assets/images/pages/marketplace/file-drop-image.svg'
import minusCircleImage from '@/assets/images/pages/minus-circle.svg'
import plusCircleImage from '@/assets/images/pages/plus-circle.svg'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cropper from '@/components/cropper/Cropper.vue'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditorsManage from '@/components/modal/editors-manage'

export default {
  name: 'ManageForm',
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    vSelect,
    TinyMCE,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    BButton,
    Cropper,
    EditorsManage,
  },
  data() {
    return {
      fileDropImage,
      minusCircleImage,
      plusCircleImage,

      form: {
        name: null,
        cover: null,
        image: null,
        rectangle_image: null,
        about: '',
        phone: '',
        email: '',
        address: '',
      },
      image: null,
      cover: null,
      rectangleImage: null,
      imagePreview: null,
      coverPreview: null,
      rectangleImagePreview: null,
      categories: [],
      category: null,
      subCategory: null,
      administrator: [],

      cropperImg: null,
      cropperImgCover: null,
      cropperRectangleImage: null,
      company: null,
      isShow: true,
      showModal: false,
      entity: 'editor',
      admins: [],
      viewers: [],
      editors: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    getAdministrators() {
      const data = [
        {
          id: this.user.id,
          name: this.user.name,
        },
      ]

      return data
    },
    getSubCategories() {
      return this.category?.subCategories ?? []
    },
    isOwner() {
      return this.company.slug === this.user.active_business_profile.slug && this.user.active_business_profile.role === 'owner'
    },
  },
  watch: {
    getSubCategories() { this.subCategory = null },
    // eslint-disable-next-line
    "form.email": {
      async handler() {
        const refs = await this.$refs
        refs.emailProvider.validate()
      },
      deep: true,
    },

  },
  created() {
    this.getCategories()

    if (this.$route.params.id) {
      this.fetchCompany()
    } else if (this.user.companies.data.length) {
      this.$router.push('/')
    }

    const [administrator] = this.getAdministrators
    this.administrator = administrator
  },
  methods: {
    uploadImg(file, type) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onloadend = () => {
        if (type === 'cover') {
          this.cropperImgCover = reader.result
        } else if (type === 'logo') {
          this.cropperImg = reader.result
        } else {
          this.cropperRectangleImage = reader.result
        }
      }
    },
    saveCompanyImage(val) {
      this.form.image = val.blob
      this.imagePreview = val.base64
    },
    saveCompanyImageCover(val) {
      this.form.cover = val.blob
      this.coverPreview = val.base64
    },
    saveRectangleImageCover(val) {
      this.form.rectangle_image = val.blob
      this.rectangleImagePreview = val.base64
    },
    async submit() {
      const isValid = await this.$refs.profileCompanyRules.validate()

      if (isValid) {
        try {
          const formData = new FormData()

          formData.append('address', this.form.address)
          formData.append('phone', this.form.phone)
          formData.append('email', this.form.email)
          formData.append('name', this.form.name)
          formData.append('categories[0]', this.category.slug)
          formData.append('categories[1]', this.subCategory.slug)

          if (this.form.cover) { formData.append('cover', new File([this.form.cover], 'cover')) }
          if (this.form.image) { formData.append('image', new File([this.form.image], 'image')) }
          if (this.form.rectangle_image) { formData.append('rectangle_image', new File([this.form.rectangle_image], 'rectangle_image')) }
          let url = '/partner-companies'
          let message = 'added'

          if (this.company) {
            url += `/${this.company.slug}`
            message = 'updated'
          }

          const res = await axiosIns.post(url, formData)

          if (res.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t(
                  `Your profile company has been successfully ${message}!`,
                ),
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
            this.$store.dispatch('auth/getUserInfo')
            await axiosIns.post(`/partner-company-about/${res.data.slug}`, { text: this.form.about })
            this.$router.push(`/profile_company/${res.data.slug}`)
          }
        } catch (e) {
          this.$store.dispatch('errorHandler', e, { root: true })
          throw e
        }
      }
    },
    async fetchCompany() {
      this.isShow = false

      try {
        const { data: { data: company } } = await axiosIns.get(`/partner-companies/${this.$route.params.id}/show`)

        this.form.address = company.address || ''
        this.form.email = company.email
        this.form.name = company.name[this.$i18n.locale]
        this.form.phone = company.phone || ''

        if (company.category.length) {
          // eslint-disable-next-line prefer-destructuring
          this.category = company.category[0]

          this.$nextTick(() => {
            // eslint-disable-next-line prefer-destructuring
            this.subCategory = company.category[0].subCategories[0]
          })
        }

        setTimeout(() => {
          this.form.about = company.about
        }, 1000)

        this.company = company
        this.isShow = true

        this.getCompanyManagers()
      } catch (e) {
        this.$router.push('/404')
      }
    },
    async getCategories() {
      try {
        const res = await axiosIns.get('categories?type=company')

        this.categories = res.data.data
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    openModal(entity) {
      this.entity = entity
      this.showModal = true
    },
    async getCompanyManagers() {
      try {
        const res = await axiosIns.get(`company-managers/${this.$route.params.id}`)

        if (res.status === 200) {
          this.admins = []
          this.viewers = []
          this.editors = []

          if (res.data.admin) {
            this.admins = res.data.admin
          }

          if (res.data.viewer) {
            this.viewers = res.data.viewer
          }

          if (res.data.editor) {
            this.editors = res.data.editor
          }
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
    async deleteManager(item, entity) {
      let params = {}
      if (entity === 'admin') {
        params = {
          admin: [item.user.slug],
        }
      } else if (entity === 'editor') {
        params = {
          editor: [item.user.slug],
        }
      } else {
        params = {
          viewer: [item.user.slug],
        }
      }

      try {
        const res = await axiosIns.delete(`company-managers/${this.$route.params.id}`, {
          data: params,
        })

        if (res.status === 200) {
          this.getCompanyManagers()
        }
      } catch (e) {
        this.$store.dispatch('errorHandler', e, { root: true })
        throw e
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_responsive.scss";

@mixin title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0F2F50;
}

.profile-company-manage-form {
  padding: 20px 10px;
}

.file-input {
  width: 100%;
}

.profile-company-form {
  &__title {
    padding-bottom: 15px;
    @include title;
  }

  &__b-title {
    padding-bottom: 15px;

    div {
      @include title;
    }
  }

  &__block {
    padding-bottom: 20px;

    > div {
      &:not(:first-child) {
        padding-top: 20px;
      }
    }
  }

  &__categories-block {
    padding-bottom: 40px;
  }

  .upload-logo {
    label {
      font-size: 14px;
    }
  }
}

.editors {
  padding-top: 15px;
  padding-bottom: 30px;

  &__title {
    > div {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 16px;
        font-weight: bold;
      }

      img {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__list {
    padding-top: 15px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      box-shadow: 0px 5px 25px rgb(0 0 0 / 10%);
      padding: 6px;

      li {
        padding: 10px;

        img {
          padding-left: 10px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
