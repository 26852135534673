<template>
  <b-container class="profile-company-manage">
    <ManageForm />
  </b-container>
</template>

<script>

import {
  BContainer,
} from 'bootstrap-vue'
import ManageForm from '@/components/profile-company/ManageForm.vue'

export default {
  components: {
    BContainer,
    ManageForm,
  },
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/abstracts/_responsive.scss";

  .profile-company-manage {
    background-color: white;
    border-radius: 10px;
  }
</style>
